export const environment = {
  production: true,
  apiurl: 'https://api.kotanapp.com',
  region: 'ap-northeast-1',
  userPoolId: 'ap-northeast-1_rsc8TbTfr',
  userPoolWebClientId: '5i80uc51ggi3m8omu7430kn5kv',
  userPoolDomain: 'kotan-auth.auth.ap-northeast-1.amazoncognito.com',
  ssoCallBackUrl: 'https://www.kotanapp.com/',
  ssoSignOutUrl: 'https://www.technium.net/mymachine/',
  state: 'test1234',
  adminRole: 'Admin,Manager,Service', // 呼び出す時 split(',') 必須
};
